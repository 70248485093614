import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFooter } from '../services/api';
import styled from 'styled-components';

const FooterSection = styled.footer`
  background: linear-gradient(135deg, #008001 0%, #66b032 100%);
  padding: 2rem 0;  /* Further reduced padding */
  color: #fff;

  .ftco-footer-widget {
    margin-bottom: 2rem;  /* Reduced margin */

    @media (max-width: 768px) {
      margin-bottom: 1rem;
      text-align: center;
    }
  }

  .ftco-heading-2 {
    font-size: 20px;  /* Adjusted font size */
    margin-bottom: 1rem;  /* Reduced margin */
    font-weight: bold;
    color: #fff;
    border-bottom: 2px solid #fff;  /* Added bottom border */
    display: inline-block;
    padding-bottom: 0.5rem;
  }

  .nav-item .nav-link {
    color: #fff;
    font-size: 14px;  /* Adjusted font size */
    margin-bottom: 5px;  /* Reduced margin */

    &:hover {
      color: #000;
      text-decoration: underline;
    }

    @media (max-width: 768px) {
      margin-bottom: 5px;
    }
  }

  .block-23 ul li {
    margin-bottom: 0.5rem;  /* Reduced margin */
    display: flex;
    align-items: center;
    color: #fff;

    .icon {
      margin-right: 10px;
      color: #fff;
    }

    .text {
      color: #fff;
      font-size: 14px;  /* Adjusted font size */
    }

    a:hover .text {
      color: #000;
    }

    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  .text-center {
    color: #fff;
    font-size: 14px;
    padding-top: 1rem;  /* Reduced padding */
  }
`;

const FooterLink = styled(Link)`
  color: #fff;
  font-size: 14px;  /* Adjusted font size */
  margin-bottom: 5px;  /* Reduced margin */
  display: block;

  &:hover {
    color: #000;
    text-decoration: underline;
  }
`;

function Footer() {
  const [footer, setFooter] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFooter = async () => {
      try {
        const data = await getFooter();
        setFooter(data);
      } catch (error) {
        console.error('Error fetching footer data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFooter();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <FooterSection className="ftco-footer ftco-no-pt">
      <div className="container">
        <div className="row mb-4">  {/* Reduced bottom margin */}
          <div className="col-md-4 pt-4">  {/* Reduced padding */}
            <div className="ftco-footer-widget pt-md-4 mb-3">  {/* Adjusted margin */}
              <h2 className="ftco-heading-2">ስለ እኛ</h2>
              <p>{footer.moto}</p>
            </div>
          </div>
          <div className="col-md-4 pt-4">  {/* Reduced padding */}
            <div className="ftco-footer-widget pt-md-4 mb-3 ml-md-4">  {/* Adjusted margin */}
              <h2 className="ftco-heading-2">አሰሳ</h2>
              <ul className="list-unstyled">
                <li className="nav-item active"><FooterLink to="/">ዋና ገፅ</FooterLink></li>
                <li className="nav-item"><FooterLink to="/aboutus">ሰለ እኛ</FooterLink></li>
                <li className="nav-item"><FooterLink to="/blog">የግብርና ትምህርት በፅሑፍ</FooterLink></li>
                <li className="nav-item"><FooterLink to="/video">የግብርና ትምህርት በቪድዮ</FooterLink></li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 pt-4">  {/* Reduced padding */}
            <div className="ftco-footer-widget pt-md-4 mb-3">  {/* Adjusted margin */}
              <h2 className="ftco-heading-2">ያግኙን</h2>
              <div className="block-23 mb-2">  {/* Adjusted margin */}
                <ul className="list-unstyled">
                  <li><span className="icon fa fa-map-marker"></span><span className="text">{footer.address}</span></li>
                  <li><span className="icon fa fa-phone"></span><span className="text">{footer.phone}</span></li>
                  <li><span className="icon fa fa-envelope"></span><span className="text">{footer.email}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <p>&copy; {new Date().getFullYear()} All rights reserved | Afrisync</p>
          </div>
        </div>
      </div>
    </FooterSection>
  );
}

export default Footer;
